import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

import Container from '../Container'

const bem = new BEMHelper('message')

export default function Message ({ title, children, type, pointUp, ...props }) {
  return (
    <Container
      small
      {...bem('', { [type]: type, 'point-up': pointUp })}
      {...props}
    >
      <div {...bem('content')}>
        {title && <h2 {...bem('title')}>{title}</h2>}
        <p {...bem('text')}>{children}</p>
      </div>
    </Container>
  )
}

Message.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  type: PropTypes.string,
  pointUp: PropTypes.bool,
}
