/* eslint-disable no-undef */

function sanitarize (string) {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '/': '&#x2F;',
    ' ': '+',
  }
  const reg = /[&<>"'/]/gi
  return (string || '').replace(reg, match => map[match])
}

function placeSearch (string) {
  const service = new google.maps.places.AutocompleteService()

  return new Promise((resolve, reject) => {
    service.getQueryPredictions({ input: string }, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        resolve(
          results.map(({ id, description, ...details }) => ({
            name: description,
            id,
            details,
          })),
        )
      } else {
        reject(status)
      }
    })
  })
}

export default async function getAddresses (search) {
  if (!google) {
    return []
  }
  const searchString = sanitarize(search)
  return placeSearch(searchString)
}
