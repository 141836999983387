import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

import Icon from '../Icon'

const bem = new BEMHelper('checkbox')

export default function Checkbox ({
  label,
  block,
  className,
  spaceless,
  ...props
}) {
  return (
    <label {...bem('', { block, spaceless }, className)}>
      <input {...bem('checkbox')} type="checkbox" {...props} />
      <span {...bem('indicator')}>
        <Icon icon="check" {...bem('icon')} />
      </span>
      <span {...bem('label')}>{label}</span>
    </label>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  block: PropTypes.bool,
  spaceless: PropTypes.bool,
  className: PropTypes.string,
}
