import axios from 'axios'

import config from '../../config.json'
const API_SOURCE = `${config.backend_server_url}_api/`

export default async function postQuota (data) {
  try {
    const response = await axios
      .post(`${API_SOURCE}price/send-request/`, { data })
      .then(response => response)
      .catch(error => error)

    return response
  } catch (error) {
    return error
  }
}
