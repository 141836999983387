import './index.scss'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Icon from '../Icon'

const bem = new BEMHelper('input')

export default class Input extends Component {
  static propTypes = {
    label: PropTypes.string,
    warning: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    spaceless: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    info: PropTypes.string,
    details: PropTypes.any,
    short: PropTypes.bool,
    defaultValue: PropTypes.string,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    autoFocus: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    onChange: PropTypes.func,
  }

  static defaultProps = {
    type: 'text',
    onChange: () => {},
  }

  constructor (props) {
    super(props)

    this.state = {
      value: props.value || props.defaultValue || '',
      showInfo: false,
    }
  }

  static getDerivedStateFromProps ({ value }, prevState) {
    if (value && prevState.value !== value) {
      return { value }
    }

    return null
  }

  componentDidMount () {
    if (this.props.autoFocus) {
      const timer =
        typeof this.props.autoFocus === 'boolean' ? 100 : this.props.autoFocus

      setTimeout(() => {
        this.input.focus()
      }, timer)
    }
  }

  handleChange = event => {
    const {
      target: { value },
    } = event
    const { type, min = 0, onChange } = this.props

    if (type === 'number') {
      this.setState({ value: isNaN(value) ? 0 : Math.max(min, value) })
    } else {
      this.setState({ value })
    }

    onChange(event)
  }

  handleStepper = value => event => {
    event.preventDefault()
    const { min = 0, onChange } = this.props

    const nextValue = Math.max(
      min,
      (this.state.value ? parseInt(this.state.value, 10) : 0) + value,
    )

    this.setState({
      value: nextValue,
    })

    onChange({ target: { value: nextValue } })
  }

  showInfo = showInfo => () => {
    this.setState({ showInfo })
  }

  render () {
    const { value, showInfo } = this.state
    const {
      type,
      label,
      spaceless,
      className,
      info,
      short,
      value: propsValue,
      defaultValue,
      onChange,
      details,
      ...props
    } = this.props

    return (
      <label {...bem('', { spaceless, short }, className)}>
        {label && <span {...bem('label')}>{label}</span>}

        <span {...bem('input-group')}>
          {type === 'textarea' ? (
            <textarea
              {...props}
              {...bem('input', type)}
              value={value}
              onChange={this.handleChange}
              ref={ref => {
                this.input = ref
              }}
            />
          ) : (
            <input
              {...props}
              {...bem('input', type)}
              type={type}
              value={value}
              onChange={this.handleChange}
              ref={ref => {
                this.input = ref
              }}
            />
          )}

          {type === 'number' && (
            <span {...bem('additions')}>
              <button
                type="button"
                onClick={this.handleStepper(-1)}
                {...bem('button')}
              >
                <Icon icon="minus" />
              </button>
              <button
                type="button"
                onClick={this.handleStepper(1)}
                {...bem('button')}
              >
                <Icon icon="plus" />
              </button>
            </span>
          )}

          {info && (
            <span {...bem('additions')}>
              <button
                type="button"
                {...bem('button')}
                onMouseOver={this.showInfo(true)}
                onMouseOut={this.showInfo(false)}
                onClick={this.showInfo(!showInfo)}
                tabIndex="-1"
              >
                <Icon icon="info" />
              </button>
            </span>
          )}
          {info && showInfo && <p {...bem('info')}>{info}</p>}
          {details && <p {...bem('details')}>{details}</p>}
        </span>
      </label>
    )
  }
}
