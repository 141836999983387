import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, navigate, graphql } from 'gatsby'
import { throttle } from 'lodash'
import ReactGA from 'react-ga'

import Hero, { Overlap } from '../../components/Hero'
import FromTo from '../../components/FromTo'
import Radio from '../../components/Radio'
import Input from '../../components/Input'
import ContainerList from '../../components/ContainerList'
import Form, { FormGroup, FormCTA } from '../../components/Form'
import Section from '../../components/Section'
import Message from '../../components/Message'
import ErrorHeader from '../../components/ErrorHeader'
import RenderContent from '../../components/RenderContent'

import imageHelper from '../../helpers/image-helper'
import getPortsV2 from '../../helpers/get-ports-v2'
import getAddresses from '../../helpers/get-addresses'
import postQuota from '../../helpers/post-quota'
import scrollTo from '../../helpers/scroll-to'
import getPosition from '../../helpers/get-position'

import {
  CONTAINER_TYPES,
  TRANSPORT_OPTIONS,
  DELIVERY_OPTIONS,
} from '../../data/price-form'

export default class Prices extends Component {
  constructor () {
    super()

    this.state = {
      summary: {
        from: {},
        to: {},
        transport: TRANSPORT_OPTIONS[0].value,
        delivery: 'standard',
        email: '',
        additional: '',
        containers: [],
      },
      missingFromTo: false,
      showForm: false,
      ports: [],
      postalCodes: [],
    }

    this.searchAddress = this.searchAddress.bind(this)
  }

  async componentDidMount () {
    const ports = await getPortsV2()

    this.setState({
      ports: ports.map(port => ({ ...port, type: 'port' })),
    })
  }

  findPort = id => {
    const { ports } = this.state
    return ports.find(item => `${item.id}` === `${id}`) || {}
  }

  getPortOrPlace = name => {
    const { ports, postalCodes } = this.state

    if (!name) {
      return {}
    }

    return (
      [...postalCodes, ...ports].find(
        item =>
          (item && item.name ? item && item.name : '').toLowerCase() ===
          name.toLowerCase(),
      ) || {}
    )
  }

  toggleModal = (key, value) => () => {
    this.setState({
      [key]: value,
    })
  }

  updateSummary = key => ({ target: { value } }) => {
    this.setState({ summary: { ...this.state.summary, [key]: value } })
  }

  updateFromTo = ({ from: fromSearch, to: toSearch }) => {
    if (fromSearch && toSearch) {
      const from = this.getPortOrPlace(fromSearch)
      const to = this.getPortOrPlace(toSearch)

      this.setState(
        {
          summary: { ...this.state.summary, from, to },
          missingFromTo: false,
        },
        () => {
          setTimeout(() => {
            this.setState({ showForm: true })
          }, 100)
        },
      )
    }
  }

  updateContainers = items => {
    const containers = items.map(item => {
      const match = CONTAINER_TYPES.find(({ value }) => value === item.type)

      return {
        type: item.type,
        quantity: item.quantity || 1,
        name: match.name,
        dangerousGoods: item.dangerousGoods || false,
        unNumber: item.unNumber || false,
        empty: item.empty || false,
        oog: item.oog || false,
        oogDescription: item.oogDescription || false,
      }
    })

    this.setState({ summary: { ...this.state.summary, containers } })
  }

  getURLdata = () => {
    const { location } = this.props

    const from =
      (location.search &&
        location.search
          .split('?from=')
          .join('')
          .split('&')[0]) ||
      null
    const toParams = location.search && location.search.split('&to=')[1]
    const to = (toParams && toParams.split('&')[0]) || null

    return location.search && from && to
      ? {
        from: this.findPort(from).name,
        to: this.findPort(to).name,
      }
      : null
  }

  handleSubmit = event => {
    event.preventDefault()
    const { summary } = this.state

    if (!summary.from.name || !summary.to.name) {
      this.setState(
        {
          missingFromTo: true,
        },
        () => {
          if (this._fromToError) {
            const to = getPosition(this._fromToError).y - 180
            scrollTo({ to, duration: 400 })
          }
        },
      )

      return
    }

    // Log use in Google Analytics
    ReactGA.event({
      category: 'Get a Price Form',
      action: 'Request Price clicked',
      value: `${summary.from.name} – ${summary.to.name}`,
    })

    postQuota(summary)

    window.sessionStorage.setItem('email', summary.email)
    navigate('/prices/thank-you/')
  }

  async searchAddress (key, value) {
    const { postalCodes } = this.state
    const newPostals = await getAddresses(value)

    this.setState({
      postalCodes: [
        ...postalCodes,
        ...newPostals
          .map(item => ({ ...item, type: 'postal' }))
          .filter(
            ({ id }) => postalCodes.map(({ id }) => id).indexOf(id) === -1,
          ),
      ],
    })
  }

  render () {
    const { ports, postalCodes, summary, showForm, missingFromTo } = this.state
    const { data } = this.props

    if (!data.ievv) {
      return <ErrorHeader />
    }

    const { heroImage } = data.ievv

    const getImage = imageHelper(data)
    const hero = heroImage ? getImage(heroImage) : {}

    const isPostal =
      summary.from.type === 'postal' || summary.to.type === 'postal'

    const urlData = this.getURLdata()
    const autoFocus = urlData ? {} : { autoFocus: 440 }

    return (
      <>
        <Helmet>
          <script
            type="text/javascript"
            src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAVgIn7X8Ol1Kb9LjrO2qpJ1xcuQfqH99Q&libraries=places"
            async
          />
        </Helmet>

        <Hero {...hero}>
          <FromTo
            ports={ports}
            postalCodes={postalCodes}
            onChange={throttle(this.searchAddress, 600)}
            onSubmit={this.updateFromTo}
            noEmptyError
            postal
            {...autoFocus}
            {...urlData}
          />

          {missingFromTo && (
            <div
              ref={ref => {
                this._fromToError = ref
              }}
            >
              <Message
                title="Please provide both origin and destination for your shipment."
                type="small"
                pointUp
              />
            </div>
          )}
        </Hero>

        <Overlap dontOverlap={!showForm}>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup title="Containers">
              <ContainerList
                onChange={this.updateContainers}
                containerTypes={CONTAINER_TYPES}
              />

              <p>
                Please see our guide on{' '}
                <Link to="/services/equipment/">Containers and Equipment</Link>{' '}
                for selecting the right containers, or ask us for help.
              </p>
            </FormGroup>

            {isPostal && (
              <FormGroup title="Transport options">
                {TRANSPORT_OPTIONS.map(item => (
                  <Radio
                    key={item.value}
                    {...item}
                    name="transport"
                    checked={summary.transport === item.value}
                    onChange={this.updateSummary('transport')}
                  />
                ))}
              </FormGroup>
            )}

            {isPostal && (
              <FormGroup title="Delivery options">
                {DELIVERY_OPTIONS.map(item => (
                  <Radio
                    key={item.value}
                    {...item}
                    name="delivery"
                    checked={summary.delivery === item.value}
                    onChange={this.updateSummary('delivery')}
                  />
                ))}
              </FormGroup>
            )}

            <FormGroup>
              <Input
                type="email"
                label="Your e-mail"
                placeholder="you@company.com"
                value={summary.email}
                onChange={this.updateSummary('email')}
                details={
                  <>
                    Your e-mail will only be used for this purpose.{' '}
                    <Link to="/about/privacy/">Read our privacy policy</Link>{' '}
                    for how we take care of your data.
                  </>
                }
                required
              />

              <Input
                type="textarea"
                label="Additional information (optional)"
                info="Please let us know if there is special considerations conserning the cargo or aspects of the sending that we should take into account."
                value={summary.additional}
                onChange={this.updateSummary('additional')}
              />
            </FormGroup>

            <FormCTA info="Your request will be handled by our sales team and you will recieve a copy of your request.">
              Get price
            </FormCTA>
          </Form>
        </Overlap>

        <Section type="light">
          <RenderContent data={data} />
        </Section>
      </>
    )
  }
}

Prices.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.any,
}

Prices.defaultProps = {
  topTask: true,
}

export const query = graphql`
  query {
    ievv(pagetypeid: { eq: "ncl_backend_pageframework.Price" }) {
      ...ArticleData
    }
    ...GetImages
  }
`
