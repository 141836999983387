import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('radio')

export default function Radio ({ label, info, full, ...props }) {
  return (
    <label {...bem('', { info, full })}>
      <span {...bem('input-group')}>
        <input {...bem('radio')} type="radio" {...props} />
        <span {...bem('indicator')} />
        <span {...bem('label')}>{label}</span>
      </span>
      {info && <p {...bem('info')}>{info}</p>}
    </label>
  )
}

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  info: PropTypes.string,
  full: PropTypes.bool,
}
