import './index.scss'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

import Icon from '../Icon'

const bem = new BEMHelper('select')

export default class Select extends Component {
  static propTypes = {
    children: PropTypes.any,
    autogrow: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      }),
    ),
    block: PropTypes.bool,
    spaceless: PropTypes.bool,
    label: PropTypes.string,
    inline: PropTypes.bool,
    ariaLabel: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
  }

  static defaultProps = {
    autogrow: true,
    label: '',
    onChange: () => {},
  }

  render () {
    const {
      label,
      block,
      spaceless,
      children,
      className,
      autogrow,
      options,
      inline,
      ...props
    } = this.props

    const WrapperNode = label ? 'label' : 'span'

    return (
      <WrapperNode
        {...bem('', { block, spaceless, autogrow, inline }, className)}
        ref={el => (this.wrapperEl = el)}
      >
        {label && <span {...bem('label')}>{label}</span>}

        <select
          {...props}
          ref={el => (this.selectEl = el)}
          onChange={this.handleChange}
        >
          {options &&
            options.map(({ value, name }) => (
              <option value={value} key={value}>
                {name}
              </option>
            ))}
          {children}
        </select>
        <Icon icon="chevron" direction="down" {...bem('arrow')} />
      </WrapperNode>
    )
  }

  handleChange = event => {
    if (this.selectEl && this.props.autogrow) {
      this.updateShadow()
    }

    this.props.onChange(event)
  }

  updateShadow () {
    if (!this.selectEl) {
      return
    }

    const selectEl = this.selectEl
    const shadow = document.createElement('select')

    let value

    if (selectEl.selectedOptions && selectEl.selectedOptions.length) {
      value = selectEl.selectedOptions[0].innerHTML
    } else {
      value = selectEl.value
    }

    const computedStyles = window.getComputedStyle(selectEl)

    shadow.style.position = 'absolute'
    shadow.style.top = '-1000px'
    shadow.style.left = '-1000px'
    shadow.style.width = 'auto'
    shadow.style.maxWidth = 'none'
    shadow.style.fontSize = computedStyles['font-size']
    shadow.style.fontFamily = computedStyles['font-family']
    shadow.style.fontWeight = computedStyles['font-weight']
    shadow.style.letterSpacing = computedStyles['letter-spacing']
    shadow.style.paddingTop = computedStyles['padding-top']
    shadow.style.paddingRight = computedStyles['padding-right']
    shadow.style.paddingBottom = computedStyles['padding-bottom']
    shadow.style.paddingLeft = computedStyles['padding-left']
    shadow.style.borderTop = computedStyles['border-top']
    shadow.style.borderRight = computedStyles['border-right']
    shadow.style.borderBottom = computedStyles['border-bottom']
    shadow.style.borderLeft = computedStyles['border-left']

    const option = document.createElement('option')
    option.value = value
    option.innerHTML = value
    shadow.appendChild(option)

    this.wrapperEl.appendChild(shadow)

    selectEl.style.width = `${shadow.offsetWidth + 6}px`

    shadow.remove()
  }

  componentDidUpdate () {
    if (this.selectEl && this.props.autogrow) {
      this.updateShadow()

      // Just to make sure it gets the correct width...
      setTimeout(() => this.updateShadow(), 200)
    }
  }

  componentDidMount () {
    if (this.selectEl && this.props.autogrow) {
      this.updateShadow()

      // Just to make sure it gets the correct width...
      setTimeout(() => this.updateShadow(), 200)
      setTimeout(() => this.updateShadow(), 300)
      setTimeout(() => this.updateShadow(), 500)
    }
  }
}
