export const CONTAINER_TYPES = [
  {
    name: "45' dry container",
    value: '45',
  },
  {
    name: "40' dry container",
    value: '40',
  },
  {
    name: "20' dry container",
    value: '20',
  },
  {
    name: "45' reefer (refrigiated)",
    value: '45-reefer',
  },
  {
    name: "20' flat rack",
    value: '20-feet-flat-rack',
    oogCompatible: true,
  },
  {
    name: "40' flat rack",
    value: '40-feet-flat-rack',
    oogCompatible: true,
  },
  {
    name: "40' flat rack high cube",
    value: '40-feet-flat-rack-high',
    oogCompatible: true,
  },
  {
    name: 'Recommendation based on my goods',
    value: 'recomandation-based-on-goods',
  },
]

export const TRANSPORT_OPTIONS = [
  {
    label: 'Recommended based on route',
    value: 'based-on-route',
    info:
      'We find the option we think suits you best based on the route you have chosen.',
  },
  {
    label: 'Conventional',
    value: 'conventional',
    info:
      'Pallets are transported from the pick up point by truck and loaded into containers at the port by our staff.',
  },
  {
    label: 'Container direct',
    value: 'direct',
    info:
      'Empty containers are transported to the pick up point where pallets can be loaded into the container.',
  },
]

export const DELIVERY_OPTIONS = [
  {
    label: 'Standard chassis',
    value: 'standard',
    info: 'Containers are discharged on the rear side of the trailer.',
  },
  {
    label: 'Sideloader',
    value: 'sideloader',
    info:
      'Use trailers that have powered cranes that lift the container up from the side of the vehicle.',
  },
]
