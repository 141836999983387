/* eslint-disable complexity */

import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Image from '../Image'
import Video from '../Video'
import ContactCard from '../ContactCard'
import Section from '../Section'
import LinkList from '../LinkList'
import { Text, Title, Quote, List } from '../Article'

import imageHelper from '../../helpers/image-helper'

export default function RenderContent ({ data }) {
  const getImage = imageHelper(data)
  const { content } = data.ievv

  if (!content) {
    return null
  }

  return (
    <>
      {content.map(({ type, ...item }, index) => {
        switch (type) {
          case 'WysiwygText':
            return <Text html={item.content} key={index} />

          case 'Heading':
            return (
              <Title level={item.level} key={index}>
                {item.title}
              </Title>
            )

          case 'ImageUpload':
            const image = getImage(item.source)

            const sizes = {
              wide: 'large',
              tight: 'default',
              left: 'left',
              right: 'right',
            }

            return (
              <Image
                fluid={image.fluid}
                alt={image.alt}
                size={sizes[item.attachmentType] || 'default'}
                key={index}
                content={item.content}
              />
            )

          case 'Quote':
            return (
              <Quote cite={item.source} key={index}>
                {item.content}
              </Quote>
            )

          case 'SortableTextList':
            return (
              <List
                title={item.title || null}
                items={item.items.map(({ content }) => content)}
                type={item.attachmentType}
                key={index}
                html
              />
            )

          case 'SortableLinkList':
            return (
              <LinkList
                title={item.title || null}
                items={item.items.map(({ content, link }) => ({
                  name: content,
                  url: link,
                }))}
                key={index}
              />
            )

          case 'Embed':
            return (
              <Video type={item.attachmentType} id={item.source} key={index} />
            )

          case 'ContactCardSelect':
            return (
              <Section
                key={index}
                full
                type={item.promoted ? 'promoted' : 'transparent'}
              >
                <Title>{item.title}</Title>

                {item.content && <Text>{item.content}</Text>}

                {item.users.map(user => (
                  <ContactCard key={user} id={user} />
                ))}
              </Section>
            )

          default:
            return null
        }
      })}
    </>
  )
}

RenderContent.propTypes = {
  data: PropTypes.shape({
    ievv: PropTypes.object.isRequired,
    images: PropTypes.object,
  }),
}

export const query = graphql`
  fragment ArticleData on Ievv {
    title
    heroImage
    heroVimeoVideo
    preamble
    pageId
    images {
      id
      alt
      image {
        name
      }
    }
    vessel {
      imo
      facts {
        type
        value
      }
    }
    content {
      type
      content
      source
      attachmentType
      level
      users
      items {
        content
        link
      }
      title
      promoted
    }
  }
`
