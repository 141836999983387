import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

import Button from '../Button'

const bem = new BEMHelper('form')

export function FormMessage ({ children, type }) {
  return <p {...bem('message', type)}>{children}</p>
}

export function FormGroup ({ children, title }) {
  return (
    <fieldset {...bem('group')}>
      <div {...bem('group-content')}>
        {title && <legend {...bem('group-title')}>{title}</legend>}
        {children}
      </div>
    </fieldset>
  )
}

export default function Form ({ children, type, aside, ...props }) {
  const Node = type

  return (
    <Node {...bem('', { aside })} {...props}>
      {aside ? (
        <>
          <div {...bem('content')}>{children}</div>
          <aside {...bem('aside')}>{aside}</aside>
        </>
      ) : (
        children
      )}
    </Node>
  )
}

export function FormCTA ({ children, info, ...props }) {
  return (
    <div {...bem('cta')}>
      <Button primary type="submit" {...bem('cta-button')} {...props}>
        {children}
      </Button>

      <p {...bem('info')}>{info}</p>
    </div>
  )
}

Form.propTypes = {
  children: PropTypes.any.isRequired,
  type: PropTypes.string,
  aside: PropTypes.any,
}

Form.defaultProps = {
  type: 'form',
}

FormGroup.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
}

FormMessage.propTypes = {
  children: PropTypes.any.isRequired,
  type: PropTypes.string,
}

FormCTA.propTypes = {
  children: PropTypes.any.isRequired,
  info: PropTypes.string,
}
