import './index.scss'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Input from '../Input'
import Select from '../Select'
import Checkbox from '../Checkbox'
import Icon from '../Icon'
import Button from '../Button'

const bem = new BEMHelper('container-list')

export default class ContainerList extends Component {
  static propTypes = {
    containerTypes: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  constructor () {
    super()

    this.state = {
      items: [{ quantity: 1, type: '45', dangerousGoods: false, oog: false }],
    }
  }

  componentDidMount () {
    this.onChange()
  }

  addItem = () => {
    const items = [...this.state.items]
    items.push({ quantity: 1, type: '45' })

    this.setState({ items }, this.onChange)
  }

  removeItem = index => () => {
    const items = [...this.state.items]
    items.splice(index, 1)

    this.setState({ items }, this.onChange)
  }

  changeItem = (index, key) => ({ target: { value } }) => {
    const items = [...this.state.items]

    if (value === 'toggle') {
      items[index][key] = !items[index][key]
    } else {
      items[index][key] = value
    }

    this.setState({ items }, this.onChange)
  }

  onChange = () => {
    this.props.onChange(this.state.items)
  }

  renderItem = (
    {
      quantity = 1,
      type = '45',
      dangerousGoods = false,
      empty = false,
      oog = false,
    },
    index,
  ) => {
    const { containerTypes } = this.props
    const currentType = containerTypes.find(({ value }) => value === type) || {}

    return (
      <li {...bem('item', { removable: index !== 0 })} key={index}>
        <div {...bem('containers')}>
          <div {...bem('container-type')}>
            <Select
              label="Container type"
              block
              spaceless
              options={containerTypes}
              autogrow={false}
              value={type}
              onChange={this.changeItem(index, 'type')}
            />
          </div>

          <div {...bem('container-details')}>
            <Input
              label="Quantity"
              type="number"
              value={quantity}
              min="1"
              spaceless
              onChange={this.changeItem(index, 'quantity')}
            />

            {index !== 0 && (
              <Button
                {...bem('remove')}
                circle={<Icon icon="x" />}
                type="button"
                aria-label="Remove"
                onClick={this.removeItem(index)}
              />
            )}
          </div>
        </div>

        <div {...bem('options')}>
          <Checkbox
            label="Empty"
            checked={empty}
            value="toggle"
            onChange={this.changeItem(index, 'empty')}
            spaceless
            {...bem('checkbox')}
          />

          <Checkbox
            label="Dangerous goods"
            checked={dangerousGoods}
            value="toggle"
            onChange={this.changeItem(index, 'dangerousGoods')}
            spaceless
            {...bem('checkbox')}
          />

          {currentType.oogCompatible && (
            <Checkbox
              label="Out of gauge"
              checked={oog}
              value="toggle"
              onChange={this.changeItem(index, 'oog')}
              spaceless
              {...bem('checkbox')}
            />
          )}

          <div {...bem('additional')}>
            {dangerousGoods && (
              <Input
                {...bem('input')}
                label="UN number"
                placeholder="UN0000"
                info="Please provide the UN number that specify the type of dangours goods in your shipment."
                short
                autoFocus
                spaceless
                required
                onChange={this.changeItem(index, 'unNumber')}
              />
            )}

            {oog && (
              <Input
                {...bem('input')}
                type="textarea"
                label="Describe the out of gauge content"
                autoFocus
                spaceless
                required
                onChange={this.changeItem(index, 'oogDescription')}
              />
            )}
          </div>
        </div>
      </li>
    )
  }

  render () {
    const { items } = this.state
    return (
      <div {...bem('')}>
        <ul {...bem('list')}>{items.map(this.renderItem)}</ul>

        <Button circle={<Icon icon="plus" />} onClick={this.addItem}>
          Add container type
        </Button>
      </div>
    )
  }
}
