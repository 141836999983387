import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('video')

export default function Video ({ type, id }) {
  const urls = {
    vimeo: `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0`,
    youtube: `https://www.youtube.com/embed/${id}?color=white&cc_load_policy=1`,
  }

  if (!type || !id || !urls[type]) {
    return null
  }

  return (
    <figure {...bem('', type)}>
      <iframe
        {...bem('player')}
        src={urls[type]}
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      />
    </figure>
  )
}

Video.propTypes = {
  type: PropTypes.oneOf(['vimeo', 'youtube']).isRequired,
  id: PropTypes.string.isRequired,
}
