import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Link from '../Link'
import Icon from '../Icon'
// import Container from '../Container'

const bem = new BEMHelper('link-list')

export default function LinkList ({ items, title }) {
  return (
    <nav {...bem('')}>
      {title && <h2 {...bem('title')}>{title}</h2>}

      <ul {...bem('links')}>
        {items.map(({ url, name }, index) => (
          <li key={url + index} {...bem('item')}>
            <Icon icon="link" {...bem('icon')} />
            <Link to={url} {...bem('link')}>
              {name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

LinkList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
}
